import { render, staticRenderFns } from "./cusRecordQuality.vue?vue&type=template&id=dade56c4&scoped=true&"
import script from "./cusRecordQuality.vue?vue&type=script&lang=js&"
export * from "./cusRecordQuality.vue?vue&type=script&lang=js&"
import style0 from "./cusRecordQuality.vue?vue&type=style&index=0&id=dade56c4&lang=less&scoped=true&"
import style1 from "./cusRecordQuality.vue?vue&type=style&index=1&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dade56c4",
  null
  
)

export default component.exports